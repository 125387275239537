import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import "./index.css";
import Home from "./routes/home";
import Feed from "./routes/feed";
import Login from "./routes/login";
import useAuth, { AuthProvider } from "./hooks/useAuth";
import reportWebVitals from "./reportWebVitals";

function RequireAuth({ children }) {
  const { sessionToken } = useAuth();

  return sessionToken === null ? <Navigate to="/login" replace /> : children;
}

function RequireNoAuth({ children }) {
  const { sessionToken } = useAuth();

  return sessionToken !== null ? <Navigate to="/" replace /> : children;
}

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <RequireAuth>
        <Home />
      </RequireAuth>
    ),
  },
  {
    path: "/feed",
    element: (
      <RequireAuth>
        <Feed />
      </RequireAuth>
    ),
  },
  {
    path: "/login",
    element: (
      <RequireNoAuth>
        <Login />
      </RequireNoAuth>
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
