import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import produce from "immer";
import useAuth from "../hooks/useAuth";
import ImageUpload from "../components/ImageUpload";

export default function Home() {
  const { sessionToken, logout } = useAuth();

  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
      "image/gif": [".gif"],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) => {
          const formData = new FormData();
          formData.append("file", file);

          // TODO Move API calls elsewhere
          const request = new XMLHttpRequest();
          request.open(
            "POST",
            `${process.env.REACT_APP_API_BASE_URL}/v1/uploads`,
            true
          );
          request.setRequestHeader("Authorization", `Bearer ${sessionToken}`);

          request.onload = (progress) => {
            // handle errors
            if (request.status === 401) {
              logout();
            } else if (request.status !== 200) {
              handleFileError(file.name);

              throw new Error(`Unexpected status code ${request.status}`);
            }

            const jsonResponse = JSON.parse(request.responseText);
            handleFileUploaded(file.name, jsonResponse.data.url);
          };

          request.onabort = (progress) => {
            console.log("Upload aborted!");
            handleFileError(file.name);
          };

          request.onerror = (progress) => {
            console.log("Upload errored!");
            handleFileError(file.name);
          };

          request.ontimeout = (progress) => {
            console.error("Upload timed out!");
            handleFileError(file.name);
          };

          request.onprogress = (progress) => {
            handleFileProgress(file.name, progress);
          };

          request.send(formData);

          return Object.assign(file, {
            uploadURL: "",
            uploadProgress: 0,
            isErrored: false,
          });
        })
      );
    },
  });

  const handleFileUploaded = useCallback((name, uploadURL) => {
    setFiles(
      produce((draft) => {
        const newFile = draft.find((f) => f.name === name);
        newFile.uploadURL = uploadURL;
      })
    );
  }, []);

  const handleFileProgress = useCallback((name, progress) => {
    setFiles(
      produce((draft) => {
        const newFile = draft.find((f) => f.name === name);
        newFile.uploadProgress =
          Math.floor(progress.loaded / progress.total) * 100;
      })
    );
  }, []);

  const handleFileError = useCallback((name) => {
    setFiles(
      produce((draft) => {
        const newFile = draft.find((f) => f.name === name);
        newFile.isErrored = true;
      })
    );
  }, []);

  useEffect(() => {
    // TODO Move API calls elsewhere
    fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/auth/session`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            logout();
            throw new Error("User logged out");
          }

          throw new Error(`Unexpected status code ${response.status}`);
        }

        return response.json();
      })
      .then(({ data }) => {
        console.log("Authenticated user:", data.user);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [sessionToken, logout]);

  const styles = {
    section: {
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
    },

    dropzone: {
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      flexDirection: "column",
      margin: "10px",
      padding: "10px",
      textAlign: "center",
      width: "90%",
      maxWidth: "960px",
      border: "solid 1px",
      minHeight: "300px",
      userSelect: "none",
    },

    thumbContainer: {
      padding: "10px",
      display: "flex",
      width: "90%",
      maxWidth: "960px",
      justifyContent: "center",
      alignContent: "center",
      flexDirection: "row",
      flexWrap: "wrap",
    },
  };

  const thumbs = files.map((file) => (
    <div key={file.name}>
      <ImageUpload file={file} />
    </div>
  ));

  return (
    <>
      <h1>servepic</h1>

      <h2>upload</h2>

      <nav>
        <ul>
          <li>
            <Link to="/feed">feed</Link>
          </li>
        </ul>
      </nav>

      <p>Welcome to servepic, a simple image upload utility.</p>

      <section style={styles.section}>
        <div
          {...getRootProps({ className: "dropzone" })}
          style={styles.dropzone}
        >
          <input {...getInputProps()} />
          <p>Drag 'n' drop some files here, or click to select files</p>
        </div>
        <aside style={styles.thumbContainer}>{thumbs}</aside>
      </section>

      <p>
        <button onClick={logout}>Logout</button>
      </p>
    </>
  );
}
