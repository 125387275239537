import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

export default function Login() {
  const navigate = useNavigate();
  const { login } = useAuth();

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();

  const handleLogin = (e) => {
    e.preventDefault();

    login(username, password)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        setError("Failed to login. Are you sure your password is correct?");
      });
  };

  return (
    <div>
      <h1>Login</h1>
      <p className="error">{error}</p>
      <form onSubmit={handleLogin}>
        Username:{" "}
        <input
          type="text"
          name="username"
          onChange={(e) => setUsername(e.target.value)}
        />
        <br />
        Password:{" "}
        <input
          type="password"
          name="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <br />
        <br />
        <button onClick={handleLogin}>Login</button>
      </form>
    </div>
  );
}
