import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";

export default function Home() {
  const { sessionToken, logout } = useAuth();
  const [files, setFiles] = useState([]);
  useEffect(() => {
    // TODO Move API calls elsewhere
    fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/uploads`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          if (response.status === 401) {
            logout();
            throw new Error("User logged out");
          } else {
            throw new Error(`Unexpected status code ${response.status}`);
          }
        }

        return response.json();
      })
      .then((data) => {
        setFiles(data.data);
      })
      .catch((error) => {
        console.log("Error fetching uploads:", error);
      });
  }, [sessionToken, logout]);

  const styles = {
    image: {
      margin: "15px",
      maxWidth: "960px",
    },
  };

  const images =
    files.length === 0 ? (
      <p>No uploads yet.</p>
    ) : (
      files.map((file) => (
        <div key={file.id}>
          <img src={file.url} style={styles.image} loading="lazy" alt="" />
        </div>
      ))
    );

  return (
    <>
      <h1>servepic</h1>

      <h2>feed</h2>

      <nav>
        <ul>
          <li>
            <Link to="/">upload</Link>
          </li>
        </ul>
      </nav>

      <p>See your latest 100 uploads here.</p>

      {images}

      <p>
        <button onClick={logout}>Logout</button>
      </p>
    </>
  );
}
