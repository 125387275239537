import * as React from "react";
import useLocalStorage from "./useLocalStorage";

const authContext = React.createContext({
  username: null,
  setUsername: (_) => {},
});

function useAuth() {
  const [sessionToken, setSessionToken] = useLocalStorage("sessionToken", null);

  function login(username, password) {
    return new Promise((resolve, reject) => {
      // TODO Move API calls elsewhere
      fetch(`${process.env.REACT_APP_API_BASE_URL}/v1/auth/session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Unexpected status code ${response.status}`);
          }

          return response.json();
        })
        .then((data) => {
          setSessionToken(data.data.sessionToken);
          resolve();
        })
        .catch((error) => {
          console.log("Error authenticating:", error);
          reject(error);
        });
    });
  }

  function logout() {
    return new Promise((res) => {
      setSessionToken(null);
      res();
    });
  }

  return {
    sessionToken,
    login,
    logout,
  };
}

export function AuthProvider({ children }) {
  return (
    <authContext.Provider value={useAuth()}>{children}</authContext.Provider>
  );
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}
