import React from "react";

export default function ImageUpload(props) {
  const styles = {
    thumb: {
      display: "inline-flex",
      width: "200px",
      height: "200px",
      margin: "5px",
      verticalAlign: "middle",
    },

    thumbImg: {
      maxWidth: "200px",
      maxHeight: "200px",
      margin: "auto",
    },

    // TODO show something more visible
    thumbImgErrored: {
      maxWidth: "200px",
      maxHeight: "200px",
      margin: "auto",
      opacity: "0.4",
    },
  };

  // If the image hasn't finished uploading, we need an object URL to preview it
  // // TODO clean up the object URL when we're done with it
  const src = props.file.uploadURL || URL.createObjectURL(props.file);

  return (
    <div style={styles.thumb}>
      <img
        style={props.file.isErrored ? styles.thumbImgErrored : styles.thumbImg}
        src={src}
        alt=""
      />
    </div>
  );
}
